import React, { useRef, useState } from "react";
import { jsPDF } from "jspdf";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from '../../assets/images/opec10.png';
import sign from '../../assets/images/sign.png';
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoutButton from "../auth/Logout";

const Certificate = () => {

  const { state } = useLocation();
  const { userData, courseData, date, certCode} = state || {};
  const [theDate,setTheDate] = useState(new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }))
  const certificateRef = useRef(null);
  const navigate = useNavigate();
  
  
  //console.log(JSON.stringify(userData,null,2))
  const hasCertificateForCourse = (user, courseId) => {
    if (!user || !user.cert_gotten || !Array.isArray(user.cert_gotten)) {
      return false;
    }
  
    return user.cert_gotten.some(cert => cert.courseId.toString() === courseId.toString());
  };

  useEffect(()=>{
    if(!userData || !courseData){
      navigate('/dashboard')
      alert('no details')
    }

    /*if(!hasCertificateForCourse(userData._id,courseData._id)){
      //navigate('/dashboard')
      alert('wrong details')
    }*/

  },[userData,courseData])
  

  useEffect(()=>{
    if(date === null){
      setTheDate(new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }))
    }else{
      setTheDate(date)
    }
  },[date])

  const downloadCertificate = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: [297, 210] // A4 size in landscape
    });

    
    
    const certificateElement = certificateRef.current;
    const width = certificateElement.offsetWidth;
    const height = certificateElement.offsetHeight;

    doc.html(certificateElement, {
      callback: (doc) => {
        // Ensure colors are preserved in PDF
        doc.setProperties({
          title: `${userData && userData.name}_Certificate`,
          creator: 'OPECSTAT'
        });
        doc.save(`${userData && userData.name}_Certificate.pdf`);
      },
      x: 0,
      y: 0,
      width: width,
      windowWidth: width,
      html2canvas: {
        scale: 0.5,
        useCORS: true,
        allowTaint: true,
        logging: true,
        backgroundColor: '#fffdf5', // Ensure background color is preserved
        letterRendering: true // Better font rendering
      }
    });
  };

  return (
    <>
      <div className="no-print d-sm-flex align-items-center justify-content-between p-4" style={{background: "#f5f5f5"}}>
          <Link to="/dashboard" className='btn btn-sm btn-primary'><span className='fa fa-fw fa-arrow-left'></span> Back to dashboard </Link><LogoutButton/>
      </div>
      <div className="d-flex flex-column align-items-center" style={{ 
        background: "#f5f5f5", 
        padding: "20px",
        width: "100%"
      }}>
        <div 
          ref={certificateRef} 
          className="certificate-container" 
          style={{
            width: "95%",
            minWidth:"1000px",
            height: "100%",
            border: "25px solid transparent",
            borderImage: "linear-gradient(45deg, #d4af37, #f5f5f5, #d4af37) 1",
            borderRadius: "5px",
            boxShadow: "0 15px 40px rgba(0, 0, 0, 0.2)",
            fontFamily: "'Cormorant Garamond', 'Times New Roman', serif",
            background: "#fffdf5",
            padding: "60px",
            color: "#333",
            overflow: "hidden",
            position: "relative",
            margin: "0 auto",
            WebkitPrintColorAdjust: "exact", // Force color printing
            colorAdjust: "exact" // Standard property for printing colors
          }}
        >
          {/* Decorative Corner Elements */}
          <div style={{
            position: "absolute",
            top: "30px",
            left: "30px",
            width: "60px",
            height: "60px",
            borderTop: "2px solid #d4af37",
            borderLeft: "2px solid #d4af37"
          }}></div>
          <div style={{
            position: "absolute",
            top: "30px",
            right: "30px",
            width: "60px",
            height: "60px",
            borderTop: "2px solid #d4af37",
            borderRight: "2px solid #d4af37"
          }}></div>
          <div style={{
            position: "absolute",
            bottom: "30px",
            left: "30px",
            width: "60px",
            height: "60px",
            borderBottom: "2px solid #d4af37",
            borderLeft: "2px solid #d4af37"
          }}></div>
          <div style={{
            position: "absolute",
            bottom: "30px",
            right: "30px",
            width: "60px",
            height: "60px",
            borderBottom: "2px solid #d4af37",
            borderRight: "2px solid #d4af37"
          }}></div>

          {/* Watermark Background */}
          <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "200px",
            fontWeight: "bold",
            color: "rgba(212, 175, 55, 0.05)",
            zIndex: "0",
            userSelect: "none",
            pointerEvents: "none",
            fontFamily: "'Great Vibes', cursive"
          }}><center>OPECSTAT<br/>ACADEMY</center></div>

          {/* Certificate Header */}
          <div className="text-center" style={{ 
            position: "relative", 
            zIndex: "1", 
            marginBottom: "40px",
            paddingTop: "20px"
          }}>
            <h1 style={{
              fontSize: "62px",
              fontWeight: "700",
              color: "#d4af37",
              letterSpacing: "4px",
              marginBottom: "15px",
              //fontFamily: "'Alex Brush', cursive",
              textShadow: "1px 1px 3px rgba(0,0,0,0.1)",
              WebkitTextFillColor: "#d4af37", // Ensure color is preserved
              printColorAdjust: "exact"
            }}>Certificate of Achievement</h1>
            
            <div style={{
              width: "250px",
              height: "1px",
              background: "linear-gradient(to right, transparent, #d4af37, transparent)",
              margin: "25px auto",
              opacity: "0.8"
            }}></div>
            
            <p style={{
              fontSize: "21px",
              letterSpacing: "3px",
              color: "#666",
              marginBottom: "20px",
              textTransform: "uppercase",
              fontFamily: "'Cormorant Garamond', serif",
              fontWeight: "500",
              WebkitTextFillColor: "#666"
            }}>This is to certify that</p>
          </div>

          {/* Recipient Name */}
          <div className="text-center" style={{ 
            position: "relative", 
            zIndex: "1",
            margin: "40px 0"
          }}>
            <h2 style={{
              fontSize: "48px",
              fontWeight: "600",
              fontFamily: "'Cormorant Garamond', serif",
              color: "#222",
              margin: "20px 0",
              textTransform: "uppercase",
              letterSpacing: "2px",
              lineHeight: "1.2",
              WebkitTextFillColor: "#222"
            }}>{userData && userData.name}</h2>
            
            <p style={{
              fontSize: "20px",
              fontStyle: "italic",
              color: "#555",
              margin: "30px 0",
              fontFamily: "'Cormorant Garamond', serif",
              WebkitTextFillColor: "#555"
            }}>has successfully completed the course of study in</p>
            
            <h3 style={{
              fontSize: "42px",
              fontWeight: "500",
              color: "#d4af37",
              margin: "30px 0",
              fontFamily: "'Cormorant Garamond', serif",
              fontStyle: "italic",
              letterSpacing: "1px",
              WebkitTextFillColor: "#d4af37"
            }}>"{courseData && courseData.title}"</h3>
          </div>

          {/* Details Section */}
          <div className="text-center" style={{ 
            margin: "40px 0",
            display: "flex",
            justifyContent: "center",
            gap: "80px",
            position: "relative",
            zIndex: "1"
          }}>
            <div>
              <p style={{ 
                fontSize: "16px",
                color: "#999",
                marginBottom: "8px",
                letterSpacing: "1px",
                textTransform: "uppercase",
                WebkitTextFillColor: "#999"
              }}>Date Awarded</p>
              <p style={{ 
                fontSize: "18px",
                fontWeight: "500",
                color: "#555",
                fontFamily: "'Cormorant Garamond', serif",
                WebkitTextFillColor: "#555"
              }}>{theDate}</p>
            </div>
            <div>
              <p style={{ 
                fontSize: "16px",
                color: "#999",
                marginBottom: "8px",
                letterSpacing: "1px",
                textTransform: "uppercase",
                WebkitTextFillColor: "#999"
              }}>Certificate ID</p>
              <p style={{ 
                fontSize: "18px",
                fontWeight: "500",
                color: "#555",
                fontFamily: "'Cormorant Garamond', serif",
                letterSpacing: "1px",
                WebkitTextFillColor: "#555"
              }}>{certCode}</p>
            </div>
          </div>

          {/* Signature Section - Improved Positioning */}
          <div style={{ 
            position: "absolute",
            bottom: "80px",
            left: "0",
            right: "0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            zIndex: "1",
            padding: "0 60px"
          }}>
            {/* Logo - Left Side */}
            <div style={{ 
              textAlign: "center",
              width: "200px"
            }}>
              <img 
                src={logo} 
                alt="Official Seal" 
                style={{ 
                  width: "120px", 
                  opacity: "0.8",
                  marginBottom: "15px",
                  filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.1))"
                }} 
              />
              <p style={{ 
                fontSize: "14px",
                color: "#666",
                fontStyle: "italic",
                WebkitTextFillColor: "#666"
              }}>Official Seal</p>
            </div>
            
            {/* Signature - Right Side */}
            <div style={{ 
              textAlign: "center",
              width: "200px",
              marginRight: "20px"
            }}>
              <img 
                src={sign} 
                alt="Signature" 
                style={{ 
                  width: "180px",
                  height: "auto",
                  marginBottom: "10px",
                  filter: "sepia(30%)"
                }} 
              />
              
              <div style={{ 
                height: "1px", 
                background: "linear-gradient(to right, transparent, #d4af37, transparent)", 
                marginBottom: "10px",
                width: "180px",
                margin: "0 auto"
              }}></div>
              <p style={{ 
                marginBottom: "0", 
                fontWeight: "600",
                fontSize: "18px",
                letterSpacing: "0.5px",
                fontFamily: "'Cormorant Garamond', serif",
                WebkitTextFillColor: "#222"
              }}>Engr. Samuel Obiniyi</p>
              <p style={{ 
                fontSize: "15px",
                color: "#666",
                letterSpacing: "0.5px",
                fontFamily: "'Cormorant Garamond', serif",
                WebkitTextFillColor: "#666"
              }}>Director, OPECSTAT</p>
            </div>
          </div>
        </div>

        {/* Download Button - Hidden when printing */}
        <button 
          className="no-print"
          onClick={() => window.print()}
          style={{
            background: "linear-gradient(to bottom, #d4af37, #b6982a)",
            color: "white",
            padding: "12px 35px",
            borderRadius: "30px",
            fontWeight: "500",
            letterSpacing: "1px",
            border: "none",
            transition: "all 0.3s",
            boxShadow: "0 5px 15px rgba(212, 175, 55, 0.3)",
            textTransform: "uppercase",
            fontSize: "14px",
            margin: "30px 0",
            cursor: "pointer"
          }}
          onMouseOver={(e) => e.target.style.transform = "translateY(-2px)"}
          onMouseOut={(e) => e.target.style.transform = "translateY(0)"}
        >
          Print Certificate
        </button>

        {/* Print-specific styles */}
        <style>{`
          @media print {
            body, html {
              margin: 0;
              padding: 0;
              width: 100%;
              height: 100%;
            }
            .certificate-outer {
              background: transparent !important;
              padding: 0 !important;
              width: 100%;
              height: 100%;
            }
            .certificate-container {
              box-shadow: none !important;
              margin: 0 !important;
              width: 100% !important;
              height: 100% !important;
              page-break-after: avoid;
              page-break-inside: avoid;
              border: 20px solid #d4af37 !important;
            }
            .no-print {
              display: none !important;
            }
            @page {
              size: A4 landscape;
              margin: 0;
            }
          }
        `}</style>
      </div>
    </>
  );
};

export default Certificate;