import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CertificateUploader = ({ instructorData }) => {
  const [courses, setCourses] = useState([]);
  const [email, setEmail] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate()

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          `https://www.opecstat.com/opecstat_server/course/getCourseByInstructorId/${instructorData._id}`
        );
        setCourses(response.data.courses || []);
      } catch (error) {
        //console.error('Error fetching courses:', error);
        setMessage('Unable to load courses. Please try again later.');
      }
    };

    fetchCourses();
  }, [instructorData]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!email || !selectedCourse) {
      setMessage('All fields are required.');
      return;
    }

    setLoading(true);
    setMessage('');

    const formData = new FormData();
    formData.append('userEmail', email);
    formData.append('courseId', selectedCourse);
    //console.log(formData)

    try {
      const response = await axios.post(
        'https://www.opecstat.com/opecstat_server/instructor/upload-certificate',
        formData,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      setMessage(response.data.message || 'Certificate processed successfully!');
      //navigate(0)
    } catch (error) {
      //console.error('Error uploading certificate:', error);
      setMessage(
        error.response?.data?.error || 'An error occurred while processing the certificate.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center">Generate Certificate</h2>
      <form onSubmit={handleFormSubmit} className="shadow p-4 rounded">
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            User Email
          </label>
          <input
            type="email"
            id="email"
            className="form-control"
            placeholder="Enter user email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="course" className="form-label">
            Select Course
          </label>
          <select
            id="course"
            className="form-select"
            value={selectedCourse}
            onChange={(e) => setSelectedCourse(e.target.value)}
            required
          >
            <option value="">-- Select a Course --</option>
            {courses
              .filter((item) => item.main_c_id === null)
              .map((course) => (
                <option className="text-dark" key={course._id} value={course._id}>
                  {course.title}
                </option>
              ))}
          </select>
        </div>
        {message && <div className="alert alert-info">{message}</div>}
        <button type="submit" className="btn btn-primary w-100" disabled={loading}>
          {loading ? 'Processing...' : 'Generate Certificate'}
        </button>
      </form><br/>
    </div>
  );
};

export default CertificateUploader;
