import React, { useState } from 'react';
import axios from 'axios';

const GiftForm = () => {
  const [giftName, setGiftName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setResults(null);

    try {
      const response = await axios.post('https://www.opecstat.com/prime_services/send-gift-suggestions', {
        giftName
      });

      if (response.data.success) {
        setResults(response.data.products);
      } else {
        setError(response.data.message || 'No products found');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch gift suggestions');
      console.error('Error:', JSON.stringify(err,null,2));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="gift-form-container">
      <h2>Find Gift Suggestions</h2>
      <form onSubmit={handleSubmit} className="gift-form">
        <div className="form-group">
          <label htmlFor="giftName">What gift are you looking for?</label>
          <input
            type="text"
            id="giftName"
            value={giftName}
            onChange={(e) => setGiftName(e.target.value)}
            required
            placeholder="e.g. perfume, watch, chocolate"
          />
        </div>
        
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Searching...' : 'Find Gifts'}
        </button>
      </form>

      {isLoading && <div className="loading">Loading gift suggestions...</div>}
      
      {error && (
        <div className="error-message">
          <p>Error: {error}</p>
        </div>
      )}
      
      {results && (
        <div className="results-container">
          <h3>Gift Suggestions:</h3>
          <div className="gift-grid">
            {results.map((product, index) => (
              <div key={index} className="gift-card">
                {product.imageSrc ? (
                  <img src={product.imageSrc} alt={product.title} className="gift-image" />
                ) : (
                  <div className="image-placeholder">Image not available</div>
                )}
                <div className="gift-details">
                  <h4>{product.title}</h4>
                  <p className="price">{product.price}</p>
                  <a 
                    href={product.productLink} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="product-link"
                  >
                    View on Jumia
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      
      <style jsx>{`
        .gift-form-container {
          max-width: 800px;
          margin: 0 auto;
          padding: 20px;
          font-family: Arial, sans-serif;
        }
        
        h2 {
          text-align: center;
          color: #333;
        }
        
        .gift-form {
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-bottom: 30px;
        }
        
        .form-group {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        
        label {
          font-weight: bold;
          font-size: 16px;
        }
        
        input {
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 4px;
          font-size: 16px;
        }
        
        button {
          padding: 12px;
          background-color: #4CAF50;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;
          transition: background-color 0.3s;
        }
        
        button:hover {
          background-color: #45a049;
        }
        
        button:disabled {
          background-color: #cccccc;
          cursor: not-allowed;
        }
        
        .loading, .error-message, .results-container {
          margin-top: 20px;
          padding: 15px;
          border-radius: 4px;
        }
        
        .loading {
          background-color: #f0f0f0;
          text-align: center;
        }
        
        .error-message {
          background-color: #ffebee;
          color: #d32f2f;
        }
        
        .results-container {
          background-color: #f9f9f9;
          border: 1px solid #eee;
        }
        
        .gift-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          gap: 20px;
          margin-top: 15px;
        }
        
        .gift-card {
          border: 1px solid #ddd;
          border-radius: 8px;
          overflow: hidden;
          transition: transform 0.2s;
        }
        
        .gift-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        
        .gift-image {
          width: 100%;
          height: 200px;
          object-fit: contain;
          background: #f5f5f5;
        }
        
        .image-placeholder {
          width: 100%;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f5f5f5;
          color: #666;
        }
        
        .gift-details {
          padding: 15px;
        }
        
        .gift-details h4 {
          margin: 0 0 10px 0;
          font-size: 16px;
          color: #333;
        }
        
        .price {
          font-weight: bold;
          color: #2e7d32;
          margin: 0 0 10px 0;
        }
        
        .product-link {
          display: inline-block;
          padding: 8px 12px;
          background-color: #ff6d00;
          color: white;
          text-decoration: none;
          border-radius: 4px;
          font-size: 14px;
          transition: background-color 0.3s;
        }
        
        .product-link:hover {
          background-color: #e65100;
        }
      `}</style>
    </div>
  );
};

export default GiftForm;