import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PaystackSubscription = ({
  prefilledEmail = "",
  prefilledName = "",
  userId = "",
  amount,
  currency = "NGN",
  phoneNumber = "08139582196",
  publicKey = "pk_live_6908f07ce5411bb8a5224fb4dbfaa0c63b1c6b4c",//"pk_test_b91e5eb380871fc38b43c1db990199057e0a1dc5",//,//"pk_test_b91e5eb380871fc38b43c1db990199057e0a1dc5",////,////// // Replace with your Paystack public key
  onSuccess,
  onFailure,
  logo = "https://opecstat.com/static/media/opec10.0ff41e7823a84d193d60.png",
  buttonDisplayText,
  btnColor = "success",
  payCode=null,
  courseId = "66e6f9b6c1eda0933df58c63",
}) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [isInstructionModalOpen, setIsInstructionModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: prefilledName,
    email: prefilledEmail,
    country: "Nigeria"
  });
  const [dCurrency, setDCurrency] = useState(currency)
  const [dAmount, setDAmount] = useState(amount)
  const [isPending, setIspending] = useState(false)
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.paystack.co/v1/inline.js';
    script.async = true;
    script.onload = () => setIsScriptLoaded(true);
    script.onerror = () => alert('Failed to load payment gateway. Please try again later.');
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(()=>{
    let newAmount = amount;
    if(dCurrency === "NGN"){
      setDAmount(amount)
    }else{
      if(dCurrency === "USD"){
        newAmount = 10
        setDAmount(10)
      }
    }
  },[dCurrency])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTermsChange = () => {
    setIsTermsChecked(!isTermsChecked); // Toggle terms checkbox state
  };

  const handleSubscription = () => {
    if (!isScriptLoaded) {
      alert('Payment gateway not ready. Please wait and try again.');
      return;
    }

    const handler = window.PaystackPop.setup({
      key: publicKey,
      email: formData.email || prefilledEmail,
      amount: amount * 100, // Paystack requires amount in kobo
      currency: dCurrency,
      ref: `tx-${Math.floor(Math.random() * 1000000)}`, // Unique transaction reference
      callback: (response) => {
        if (response.status === 'success') {
          if(payCode && payCode === "share"){
                /* Make the API call*/
                const response = fetch(
                    `https://opecstat.com/opecstat_server/user/update-course-shared/${userId}/${courseId}`
                )
                .then((response)=>{
                  const contentType = response.headers.get('content-type');
                  if (response.ok) {
                      let data;
                      if (contentType && contentType.includes('application/json')) {
                          data = response.json(); // Parse as JSON
                      } else {
                          data = response.text(); // Parse as plain text
                      }
                      navigate(0)
                      //console.log('Activate Program Data:', data);
                  } else {
                      const errorMessage = contentType.includes('application/json')
                          ? response.json()
                          : response.text();
                      console.error('Failed to activate program:', errorMessage);
                  }
                })
                .catch((error)=>{
                  console.error('Failed to activate program:', error);
                })
          }else{
            fetch('https://opecstat.com/opecstat_server/user/subscribe2', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ userEmail: formData.email }),
            })
            .then((res) => {
              if (res.ok) {
                setIsInstructionModalOpen(true);
              } else {
                alert('Failed to update subscription status. Contact support@opecstat.com');
              }
            })
            .catch(() => {
              alert('An error occurred while updating your subscription.');
            });
          }
        } else {
          alert('Payment was not successful.');
        }
      },
      onClose: () => {
        console.log("Payment cancelled!");
      },
    });
    handler.openIframe();
  };

  const handleProceedToPayment = () => {
    setIspending(true)
    if (!formData.name || !formData.email) {
      alert("Please provide your name and email.");
      setIspending(false)
      return;
    }
    
    if (!isTermsChecked) {
      alert("You must agree to the terms and conditions before proceeding.");
      setIspending(false);
      return;
    }
    fetch('https://opecstat.com/opecstat_server/user/addUser', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        country: formData.country,
        password: "default123",
        intendCourse: courseId,
        //freeOrigin: 80,
      }),
    }).then((response) => {
        //console.log("The res: ", JSON.stringify(data)); // Log the actual response data
        setIsModalOpen(false);
        setIspending(false)
        handleSubscription();
    }).catch((e) => {
        console.log("The err: ", e);
        setIspending(false)
    });
    setIspending(false)
  };

  const openModalOrProceed = () => {
    if (prefilledName && prefilledEmail) {
      handleSubscription();
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <div>
      <button className={`btn btn-lg btn-${btnColor}`} onClick={openModalOrProceed} disabled={!isScriptLoaded}>
        {buttonDisplayText}
      </button>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content text-dark">
            <h3 className="fw-bold">Enter Your Details</h3>
            <form>
              <div>
                <label style={{ float: "left" }}>Name:</label>
                <input
                  type="text"
                  name="name"
                  className="form-control mb-1"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label style={{ float: "left" }}>Email:</label>
                <input
                  type="email"
                  name="email"
                  className="form-control mb-1"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              {/*<div className='mb-2'>
                  <label style={{ float: "left" }}>Country:</label>
                  <select 
                      name="country" 
                      className="form-control mb-1" 
                      value={formData.country} 
                      onChange={handleInputChange}
                      id="country"
                      placeholder="Country of Residence"
                  >
                      
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                  <option value="Botswana">Botswana</option>
                  <option value="Brazil">Brazil</option>
                  <option value="Brunei">Brunei</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cabo Verde">Cabo Verde</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Central African Republic">Central African Republic</option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo (Congo-Brazzaville)">Congo (Congo-Brazzaville)</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czechia (Czech Republic)">Czechia (Czech Republic)</option>
                  <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Eswatini (fmr. 'Swaziland')">Eswatini (fmr. "Swaziland")</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Greece">Greece</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-Bissau">Guinea-Bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran">Iran</option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Ivory Coast">Ivory Coast</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Laos">Laos</option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libya">Libya</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia">Micronesia</option>
                  <option value="Moldova">Moldova</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar (formerly Burma)">Myanmar (formerly Burma)</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="North Korea">North Korea</option>
                  <option value="North Macedonia">North Macedonia</option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestine State">Palestine State</option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Romania">Romania</option>
                  <option value="Russia">Russia</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Korea">South Korea</option>
                  <option value="South Sudan">South Sudan</option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syria">Syria</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania">Tanzania</option>
                  <option value="Thailand">Thailand</option>
                  <option value="Timor-Leste">Timor-Leste</option>
                  <option value="Togo">Togo</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">United Arab Emirates</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States of America">United States of America</option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Vatican City">Vatican City</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietnam">Vietnam</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                  </select>
              </div>

              <div className='mb-2'>
                  <label style={{ float: "left" }}>Currency:</label>
                  <select 
                      name="dCurrency" 
                      className="form-control mb-1" 
                      value={dCurrency} 
                      onChange={(e) => {
                        setDCurrency(e.target.value);
                      }}
                  >
                      
                  <option value="NGN">NGN</option>
                  <option value="USD">USD</option>
                  <option value="GBP" disabled>GBP</option>
                  </select>
              </div>*/}
              <div className="form-check mt-3" style={{fontSize:"14px"}}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="termsCheckbox"
                  checked={isTermsChecked}
                  onChange={handleTermsChange}
                  style={{float:"left", cursor:"pointer"}}
                />
                <label className="form-check-label" htmlFor="termsCheckbox">
                  I agree to the <a href="/terms-and-conditions.html" target="_blank" rel="noopener noreferrer">terms and conditions</a>
                </label>
              </div>
              <button type="button" className={`btn btn-md btn-success mb-1 my-2 ${isPending ? 'disabled' : ''}`} onClick={handleProceedToPayment}>
                {isPending ? "Loading...":"Proceed to Payment"}
              </button><br />
              <button
                type="button"
                className="btn btn-sm btn-outline"
                onClick={() => setIsModalOpen(false)}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}

      {isInstructionModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content bg-dark text-white">
            <h3 className="fw-bold ">Payment Successful</h3>
            <h4>Please read carefully before proceeding...</h4>
            <p>Follow the steps below to log in and access your classes:</p>
            
            <ul className="list-group list-group-striped">
              <li className="list-group-item text-start">1. Navigate to the login page by clicking the button below.</li>
              <li className="list-group-item text-start">2. Click on the "Reset Password" link on the login page.</li>
              <li className="list-group-item text-start">3. Enter the same email you used for payment.</li>
              <li className="list-group-item text-start">4. Set a new password.</li>
              <li className="list-group-item text-start">5. Use your new password to log in and start your classes.</li>
            </ul>
        
            <button className="btn btn-lg btn-success mt-3" onClick={() => navigate('/login')}>
              Go to Login
            </button>
          </div>
        </div>
      
      )}

      <style jsx>{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 9999;
        }

        .modal-content {
          background: white;
          padding: 20px;
          border-radius: 5px;
          width: 300px;
          text-align: center;
        }
      `}</style>
    </div>
  );
};

export default PaystackSubscription;
