import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Spinner, Collapse, Modal, Pagination } from 'react-bootstrap';
import CertificateUploader from './utilityComponents/CertificateUploader';

const UsersWithCertificates = ({ instructorData, renderView }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://www.opecstat.com/opecstat_server/course/usersWithCertificates/${instructorData._id}`
        );
        setUsers(response.data);
        //console.log("User with certs", JSON.stringify(response.data,null,2));
      } catch (error) {
        //console.error('Error fetching data:', JSON.stringify(error,null,2));
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [instructorData]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = users.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(users.length / itemsPerPage);

  const toggleRow = (userId) => {
    setExpandedRows(prev => 
      prev.includes(userId) 
        ? prev.filter(id => id !== userId) 
        : [...prev, userId]
    );
  };

  if (loading) return <Spinner animation="border" />;

  return (
    <div className="mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Certified Students</h3>
        <Button 
          variant="primary" 
          onClick={() => setShowModal(true)}
        >
          Add Certificate
        </Button>
      </div>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Student Name</th>
            <th>Email</th>
            <th>Certified Courses Count</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((user, index) => (
            <React.Fragment key={user._id}>
              <tr>
                <td>{indexOfFirstItem + index + 1}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.certificates.length}</td>
                <td>
                  <Button 
                    size="sm" 
                    onClick={() => toggleRow(user._id)}
                    variant="link"
                  >
                    {expandedRows.includes(user._id) ? 'Hide' : 'Show'} Courses
                  </Button>
                </td>
              </tr>
              <tr>
                <td colSpan="5" className="p-0">
                  <Collapse in={expandedRows.includes(user._id)}>
                    <div className="p-3 bg-light">
                      <h5>Certified Courses:</h5>
                      <Table size="sm" bordered>
                        <thead>
                          <tr>
                            <th>Course Title</th>
                            <th>Date Certified</th>
                            {/*<th>Certificate</th>*/}
                          </tr>
                        </thead>
                        <tbody>
                          {user.certificates.map((cert, idx) => (
                            <tr key={idx}>
                              <td>{cert.courseTitle}</td>
                              <td>{new Date(cert.gottenAt).toLocaleDateString()}</td>
                              {/*<td>
                                {cert.cert_img ? (
                                  <a href={cert.cert_img} target="_blank" rel="noopener noreferrer">
                                    View Certificate
                                  </a>
                                ) : 'No image'}
                              </td>*/}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Collapse>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="d-flex justify-content-center mt-3">
          <Pagination>
            <Pagination.First 
              onClick={() => setCurrentPage(1)} 
              disabled={currentPage === 1} 
            />
            <Pagination.Prev 
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} 
              disabled={currentPage === 1} 
            />
            
            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
              let pageNum;
              if (totalPages <= 5) {
                pageNum = i + 1;
              } else if (currentPage <= 3) {
                pageNum = i + 1;
              } else if (currentPage >= totalPages - 2) {
                pageNum = totalPages - 4 + i;
              } else {
                pageNum = currentPage - 2 + i;
              }
              
              return (
                <Pagination.Item
                  key={pageNum}
                  active={pageNum === currentPage}
                  onClick={() => setCurrentPage(pageNum)}
                >
                  {pageNum}
                </Pagination.Item>
              );
            })}
            
            <Pagination.Next 
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} 
              disabled={currentPage === totalPages} 
            />
            <Pagination.Last 
              onClick={() => setCurrentPage(totalPages)} 
              disabled={currentPage === totalPages} 
            />
          </Pagination>
        </div>
      )}

      {/* Add Certificate Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Award New Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CertificateUploader instructorData={instructorData} />y
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UsersWithCertificates;