import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/images/opec10.png';
import loader1 from '../../assets/images/03-42-11-849_512.gif'
import AuthSidebar from './Sidebar';
import '../../assets/styles/font_style.css';
import google from '../../assets/images/93u2c.png'
import axios from 'axios';
import useCheckInternet from '../generalComponents/checkOnline';

const RegForm = () => {
    const [currentStep,setCurrentStep] = useState(1)
    const [fname, setFName] = useState('')
    const [lname, setLName] = useState('')
    const [email, setEmail] = useState('')
    const [referral, setReferral] = useState('')
    const [country, setCountry] = useState('Nigeria')
    //const [skillPossessed, setSkillPossessed] = useState('')
    //const [skillRequired, setSkillRequired] = useState('')
    //const [proofOfWork, setProofOfWork] = useState('')
    const [password, setPassword] = useState('')
    const [cPassword, setCPassword] = useState('')
    const [isPending, setIsPending] = useState(false)
    const [error, setError] = useState({});
    const [serverError, setServerError] = useState(null);
    const [hideTop, setHideTop] = useState(false)
    const [freeOrigin, setFreeOrigin] = useState(true)//set to false when you want only vi to set to true
    const [courseCode, setCourseCode] = useState(false)
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [isRequireEnrollCode, setIsRequireEnrollCode] = useState(false)
    const navigate = useNavigate()
    const {freeStatus,courseCodeStatus} = useParams()

    useEffect(()=>{
        if(freeStatus && freeStatus==='fromDirect'){
            setFreeOrigin(80)//Leave it as it is when you want only vi to set to true
        }else{
            setFreeOrigin(70)//set to false when you want only vi to set to true
            //setCourseCode(freeStatus)
        }
    },[freeStatus])

    useEffect(()=>{
        if(courseCodeStatus){
            setCourseCode(courseCodeStatus)//Leave it as it is when you want only vi to set to true
        }else{
            setIsRequireEnrollCode(true)
            if(freeStatus === 'cert'){
                setCourseCode("rlfok")
            }else{
                setCourseCode(freeStatus)
            }
            
        }
    },[courseCodeStatus])
    //alert(freeStatus)
    const validateField = (field, value) => {
        let error = '';
        if(value !== undefined){
            if (field === 'fname') {
                if (value.trim() === '') {
                    error = 'First Name is required';
                }
            } if (field === 'lname') {
                if (value.trim() === '') {
                    error = 'Last Name is required';
                }
            } else if (field === 'email') {
                // Basic email validation (adjust as needed)
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(value)) {
                    error = 'Invalid email address';
                }
            } else if (field === 'country') {
                if (value.trim() === '') {
                    error = 'Country is required';
                }
            } else if (field === 'password') {
                const passwordRegex = /^(?=.*[A-Z]).{8,}$/;;
                if (!passwordRegex.test(value)) {
                error = 'Password must be at least 8 characters long and contain at least one uppercase letter';
                }
            } else if (field === 'cPassword') {
                if(value !=='undefined'){
                    if(value[0].trim() !== value[1].trim()){
                        error = "Password does not match"
                    }
                }
            }
        }
        setError((prevErrors) => ({ ...prevErrors, [field]: error }));
    };
    

  const handleTermsChange = () => {
    setIsTermsChecked(!isTermsChecked); // Toggle terms checkbox state
  };

    const isOnline = useCheckInternet();
    const handleFormSubmit = async (e) =>{
        e.preventDefault()
        if (!isOnline) {
            setServerError('You are offline. Please check your internet connection and try again.');
            return;
        }
        if(isRequireEnrollCode){
            if (referral.trim().toLowerCase() === '') {
                alert("Please enter your enrollment code to register for free. If you haven't received one, complete the required task in the group and submit a screenshot as proof.");
                return;
            } else if (referral.trim().toLowerCase() !== "zorix") {
                alert("Enrollment code is incorrect. If you haven't received one, complete the required task in the group and submit a screenshot as proof.");
                return;
            }
            
        }
        if (!isTermsChecked) {
            alert("You must agree to the terms and conditions before proceeding.");
            return;
        }
        validateField('fname',fname)
        validateField('lname',lname)
        validateField('email',email)
        validateField('country',country)
        validateField('password',password)
        const hasErrors = Object.values(error).some((error) => error !== '');
        if (hasErrors) {
        return;
        }
        setIsPending(true)
        const name = fname + " " + lname
        const data = {name, email, country, password, cPassword, freeOrigin, courseCode, referral}
        //alert(JSON.stringify(data))
        try {
            const response = await axios.post('https://www.opecstat.com/opecstat_server/user/addUser', JSON.stringify(data), {
            headers: { 'Content-Type': 'application/json' },
            });

            setIsPending(false);
            if (response.status === 200) {
                const token = response.data.token; // Assuming token is returned from backend
                localStorage.setItem('token', token);
                console.log(response.message)
                console.log('User Registered');
                setHideTop(true) 
                setServerError(null)
                setCurrentStep(currentStep + 1);
                navigate("/login")
            }else{
                if(response.status === 409){
                    setServerError('User already exists. Please Login')
                }else{
                    setServerError('User already exists. Please Login')//response.message)
                }
            }
        } catch (error) {
            setIsPending(false);
            setServerError('User already exists. Please Login')//(error.data.message)
            //console.error(error.data.message)
        }

    }

   

    const renderStep = () => {
        switch(currentStep){
            case 1:
                return(
                    <>
                        <div className='mb-2'>
                            <input 
                                type="text" 
                                id="fname"
                                value={fname}
                                onChange={(e) => {
                                    setFName(e.target.value);
                                    validateField('fname', e.target.value);
                                }}
                                onBlur={() => validateField('fname', fname)}
                                className="rounded-lg px-6 py-3 w-full" 
                                placeholder="First Name"
                            />
                            {error.fname && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.fname}</p>}
                        </div>
                        <div className='mb-2'>
                            <input 
                                type="text" 
                                id="lname"
                                value={lname}
                                onChange={(e) => {
                                    setLName(e.target.value);
                                    validateField('lname', e.target.value);
                                }}
                                onBlur={() => validateField('lname', lname)}
                                className="rounded-lg px-6 py-3 w-full" 
                                placeholder="Last Name"
                            />
                            {error.lname && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.lname}</p>}
                        </div>
                        <div className='mb-2'>
                            <input 
                                type="email" 
                                id="email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    validateField('email', e.target.value);
                                }}
                                onBlur={() => validateField('email', email)}
                                className="rounded-lg px-6 py-3 w-full" 
                                placeholder="Email Address"
                            />
                            {error.email && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.email}</p>}
                        </div>
                        <div className='mb-2'>
                            <select 
                                name="country" 
                                className="rounded-lg px-6 py-3 w-full text-gray-500" 
                                value={country} 
                                onChange={(e)=>{setCountry(e.target.value)}} 
                                onBlur={() => validateField('country', country)}
                                id="country"
                                placeholder="Country of Residence"
                            >
                                
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Brazil">Brazil</option>
                            <option value="Brunei">Brunei</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cabo Verde">Cabo Verde</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo (Congo-Brazzaville)">Congo (Congo-Brazzaville)</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czechia (Czech Republic)">Czechia (Czech Republic)</option>
                            <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Eswatini (fmr. 'Swaziland')">Eswatini (fmr. "Swaziland")</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Greece">Greece</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran">Iran</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Ivory Coast">Ivory Coast</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Laos">Laos</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libya">Libya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia">Micronesia</option>
                            <option value="Moldova">Moldova</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montenegro">Montenegro</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar (formerly Burma)">Myanmar (formerly Burma)</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="North Korea">North Korea</option>
                            <option value="North Macedonia">North Macedonia</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Palestine State">Palestine State</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Romania">Romania</option>
                            <option value="Russia">Russia</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                            <option value="Saint Lucia">Saint Lucia</option>
                            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Korea">South Korea</option>
                            <option value="South Sudan">South Sudan</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syria">Syria</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania">Tanzania</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Timor-Leste">Timor-Leste</option>
                            <option value="Togo">Togo</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States of America">United States of America</option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Vatican City">Vatican City</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Vietnam">Vietnam</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                            {error.country && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.country}</p>}
                        </div>
                        <div className='mb-2'>
                            <input 
                                type="password" 
                                id="password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    validateField('password', e.target.value);
                                }}
                                onBlur={() => validateField('password', password)}
                                className="rounded-lg px-6 py-3 w-full" 
                                placeholder="Password"
                            />
                            {error.password && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.password}</p>}
                        </div>
                        <div className='mb-2'>
                            <input 
                                type="password" 
                                id="cpassword"
                                value={cPassword}
                                onChange={(e) => {
                                    setCPassword(e.target.value);
                                    validateField('cPassword', [password,e.target.value]);
                                }}
                                onBlur={() => validateField('cPassword', [password,cPassword])}
                                className="rounded-lg px-6 py-3 w-full" 
                                placeholder="Confirm Password"
                            />
                            {error.cPassword && <p style={{float:"left"}} className="text-danger mt-1 mb-3 ml-2">{error.cPassword}</p>}
                        </div>
                        {isRequireEnrollCode && <div className='mb-2'>
                            <input 
                                type="text" 
                                id="referral"
                                value={referral}
                                onChange={(e) => {
                                    setReferral(e.target.value);
                                }}
                                className="rounded-lg px-6 py-3 w-full" 
                                placeholder="Enter your Enrollment Code"
                            />
                        </div>}
                        
                        <div className="form-check mt-3 mb-2">
                            <input
                            type="checkbox"
                            className="form-check-input"
                            id="termsCheckbox"
                            checked={isTermsChecked}
                            onChange={handleTermsChange}
                            style={{float:"left", cursor:"pointer"}}
                            />
                            <label className="form-check-label" htmlFor="termsCheckbox">
                            I agree to the <a href="/terms-and-conditions.html" target="_blank" rel="noopener noreferrer">terms and conditions</a>
                            </label>
                        </div>
                    </>
                );
            case 2:
                return(
                    <>
                        <div className='pt-10 mt-5 bg-warning rounded-lg p-4 pt-2' style={{color:"black"}}><br/>
                            <h2 className='font-bold  text-4xl mb-2'>Welcome {fname}</h2>
                            <p className=' text-lg mb-2'>
                                You are now a part of Opecstat Academy Community.<br></br>
                                Please complete your profile so that you can build your 
                                network with people who have the skills you need
                            </p>
                            <p className=' text-md mt-5 mb-5'>
                                <Link to="/dashboard" className='btn btn-primary d-block py-3'>Complete Your Profile</Link>
                            </p>
                        </div>
                    </>
                )
            default:
                return null;
        }

    }

    
    return (
        <section className="" style={{fontFamily:"Helvetica", backgroundColor:"#fff"}}>
            <div className="row pb-5">
                <AuthSidebar/>
                <div className='col-md-6 pb-5'>
                    <center>
                        {freeOrigin}
                        {isPending && <div className='h-screen text-xl mt-10'><img src={loader1} alt='loader' /><br/>Registration in Progress...</div>}
                        {
                            !isPending && 
                            <div style={{width:'80%'}}>
                                {
                                    !hideTop &&
                                    <center>
                                        <img src={logo} alt='' className='w-25 mt-1'/>
                                        <p className='fs-3 h-50 fw-bold mt-1 d-block d-md-none d-lg-none '>
                                            Opecstat Academy<br/>Student Registration
                                            
                                        </p>
                                        <p className='fs-3 h-50 fw-bold mt-1 d-none d-md-block d-lg-block '>
                                            Student Registration 
                                        </p>
                                    </center>
                                }
                                
                                <form onSubmit={handleFormSubmit} >
                                    {serverError && <div className=" alert alert-danger px-4 py-2 rounded-md">User already exists. Please Login{/*serverError*/}</div>}
                                    {renderStep()}
                                    {!hideTop && currentStep ===1 && (
                                        <div className='mb-2 mt-1 d-grid text-white'>
                                            <button type="button"  onClick={handleFormSubmit} className='btnpx-3' style={{color:"#fff",backgroundColor:"#FF7518"}}>Create Your Account</button>
                                        </div>)
                                    }
                                    {/*currentStep ===2 && (
                                        <div className='mb-2'>
                                            <button type="button" onClick={handleLogin}  className='border-1 border-solid border-gray-800 bg-orange-200 hover:bg-zinc-900 hover:text-white text-white w-full'>Continue</button>
                                        </div>)*/
                                    }
                                </form>
                                {/*<center className='text-gray-600 my-6'>
                                   -------- OR -------

                                    <div className='mt-4 mb-2'>
                                        <div className='w-100' ><img src={google} alt='sign_options' style={{width:"100%", height:"110px"}} className='cursor-pointer' onClick={handleInfoClick}/></div>
                                    </div>
                                </center>*/}
                                {
                                    !hideTop &&     
                                    <div className='mb-5'>
                                        Already have an account? <Link to="/login" className='text-primary'>Login here</Link><br/><br/>
                                    </div>
                                }           
                            </div>
                        }
                    </center>
                </div>
            </div>
        </section>
    );
}

export default RegForm;

