import React from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ResourceControlComponent from "./utilityComponents/ResourceControl";

const MyCerts = ({userData}) =>{
    const navigate = useNavigate()


    const handleViewCert = (courseData, date=null, certificateCode = "NIL") =>{
        if(userData.sub_status === 60 || userData.sub_status === 50){
            alert("You currently do not have access to your certificate. Please complete the required steps to unlock it.");
        }else{
            navigate('/cert', {
                state: {
                userData: userData,
                courseData: courseData,
                date: new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
                certCode: certificateCode
                }
            });
        }        
    }
    
    
    return(
        <>
            <div className="row">
                <h3 className="text-dark my-3 fw-bold"><i className="fa fa-certificate text-warning"></i> {userData.name} Certificates <i className="fa fa-certificate text-warning"></i></h3>
            </div>
            {
                userData.sub_status === 50 || userData.sub_status === 60?(
                    <center><div className="container bg-white p-4 mb-3 w-75 rounded-lg shadow-lg"><b className='text-dark'>{`${userData.sub_status === 50 ? 'You are currently disenrolled and Cannot Particiapte in this program or view certificates...':'Congratulations on successfully completing the First Module of your program! To proceed to the Next Module, kindly make the required payment for your certificate. Please note that this payment also covers all future certificates of your current program' }`}<br/><br/>To resume your program: <br/><br/><span className='w-50'><ResourceControlComponent videoData={null} userData={userData} type="subscribe" /></span><br/><br/><span className='text-danger'>Kindly note that the enrollment fee goes back to &#8358;67,000 soon</span></b></div></center>
                ):(<></>)
            }
            <Table bordered hover striped responsive>
                <thead>
                <tr>
                    <th>S/N</th>
                    <th>Course Title</th>
                    <th>Certificate</th>
                </tr>
                </thead>
                <tbody>
                    {userData.cert_gotten.length === 0 && <tr><td colSpan={3}><center>No Certificate yet...<br/> Complete your program to view your certificat</center></td></tr>}
                    {userData.cert_gotten.length >0 && userData.cert_gotten.map((item, index) => (
                        <tr key={index}>
                            {/*console.log(JSON.stringify(item,null,2))*/}
                        <td>{index + 1}</td>
                        <td>{item.courseId.title}</td>
                        <td>
                            <button onClick={()=>handleViewCert(item.courseId,item.gottenAt,item.certCode)} target="_blank" rel="noopener noreferrer">
                            View Certificate
                            </button>
                        </td>
                        </tr>
                    ))}                                                     
                </tbody>
            </Table>
        </>
    )
}

export default MyCerts