import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const CourseProgress = ({ userId, mainCId }) => {
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState({ 
    watched: 0, 
    total: 0,
    courseTitle: '' 
  });

  // Styles
  const styles = {
    container: {
      width: '100%',
      padding: '20px',
      maxWidth: '600px',
      margin: '0 auto',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    progressBarContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      marginTop: '10px'
    },
    loader: {
      textAlign: 'center',
      padding: '20px',
      color: '#666'
    },
    completionText: {
      mt: 1, 
      color: '#666',
      fontStyle: 'italic'
    }
  };

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const res = await axios.get(
          `https://www.opecstat.com/opecstat_server/user/progress/${userId}/${mainCId}`,
          //{ timeout: 5000 } // 5 second timeout
        );
        
        setProgress(res.data.progress);
        setDetails({
          watched: res.data.watchedCount,
          total: res.data.totalVideos,
          courseTitle: res.data.mainCourseTitle || 'Course'
        });
      } catch (err) {
        console.error('Progress fetch error:', err);
        setDetails(prev => ({ ...prev, courseTitle: 'Course' }));
      } finally {
        setIsLoading(false);
      }
    };

    fetchProgress();
  }, [userId, mainCId]);

  if (isLoading) return <div style={styles.loader}>Loading progress...</div>;

  return (
    <div style={styles.container}>
      <Typography variant="h6" component="div" gutterBottom>
        {details.courseTitle} Progress  
      </Typography>
      
      <div style={styles.progressBarContainer}>
        <Box sx={{ width: '100%' }}>
          <LinearProgress 
            variant="determinate" 
            value={progress} 
            sx={{
              height: 10,
              borderRadius: 5,
              backgroundColor: '#e0e0e0',
              '& .MuiLinearProgress-bar': {
                borderRadius: 5,
                backgroundColor: progress > 70 ? '#4caf50' : 
                               progress > 30 ? '#1976d2' : '#ff9800'
              }
            }}
          />
        </Box>
        <Typography variant="body2" color="text.secondary">
          {`${progress}%`}
        </Typography>
      </div>
      
      <Typography variant="body2" sx={styles.completionText}>
        {details.watched} of {details.total} videos completed
      </Typography>
      
      {progress === 100 && (
        <Typography variant="body2" sx={{ mt: 1, color: '#4caf50', fontWeight: 'bold' }}>
          Course completed! 🎉
        </Typography>
      )}
    </div>
  );
};

export default CourseProgress;